import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pluralize from 'pluralize';
import classNames from 'classnames';

import FilterPills from '../generic/FilterPills';
import FilterEntry from '../generic/FilterEntry';
import ResetFiltersButton from '../generic/ResetFiltersButton';
import { NextIcon } from '../../generic/Icons';
import { setOpenFilter as setOpenFilterAction } from '../actions';
import { filtersShape, selectedShape, displayNamesShape } from '../shapes';
import MobileSearchFilterMenu from './MobileSearchFilterMenu';
import HOISTED_DIETS from '../generic/hoistedDiets.json';

if (process.env.CLIENT) {
  require('./MobileSearchFiltersMenu.scss'); // eslint-disable-line global-require
}

const MobileSearchFilterResultsButton = ({ totalCount, onClick }) => (
  <button
    className="mobile-search__filters-menu__results gel-great-primer"
    onClick={onClick}
    type="button"
  >
    Show {totalCount} recipes
  </button>
);

MobileSearchFilterResultsButton.propTypes = {
  totalCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

const MobileSearchFilterButton = ({ name, onClick, count }) => (
  <button
    className={classNames('mobile-search__filter__button', {
      'mobile-search__filter__button--selected': count > 0,
    })}
    onClick={onClick}
    type="button"
  >
    <span className="mobile-search__filter__button__text">
      {count > 0 ? count : 'All'} {pluralize(name, count)}
    </span>
    <NextIcon />
  </button>
);

MobileSearchFilterButton.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
};

const MobileSearchFiltersMenu = ({
  displayNames,
  selected,
  filters,
  filterOrder,
  totalCount,
  searchTerm,
  changeHandler,
  clearHandler,
  openFilter,
  prevOpenFilter,
  setOpenFilter,
}) => {
  const focusRef = useRef();

  const openMenu = () => {
    setOpenFilter('menu');
    focusRef.current.focus();
  };

  const closeMenu = () => {
    setOpenFilter('');
    focusRef.current.focus();
  };

  const animationClasses =
    prevOpenFilter !== openFilter
      ? {
          'slide-out-right': prevOpenFilter === 'menu' && openFilter === '',
          'slide-out-left': prevOpenFilter === 'menu' && openFilter !== '',
          'slide-in-left': prevOpenFilter === '' && openFilter === 'menu',
          'slide-in-right': prevOpenFilter !== '' && openFilter === 'menu',
        }
      : {};

  return (
    <div className="mobile-search__filters">
      <button
        className="mobile-search__filters-menu__expander"
        onClick={openMenu}
        ref={focusRef}
        type="button"
      >
        <div className="mobile-search__filters-menu__expander__label gel-great-primer-bold">
          Filter by
        </div>
        <NextIcon />
      </button>
      {(openFilter === 'menu' || prevOpenFilter === 'menu') && (
        <div className={classNames('mobile-search__filters-menu', animationClasses)}>
          <div className="mobile-search__filters-menu__inner">
            <MobileSearchFilterResultsButton totalCount={totalCount} onClick={closeMenu} />
            <div className="mobile-search__filters-menu__heading gel-pica">Filter by:</div>
            {filters
              .filter(({ name }) => HOISTED_DIETS.includes(name))
              .map(({ filter }) =>
                filter.map(({ type, typeName, selected: s, count }) => (
                  <div
                    key={type}
                    className={classNames('search-filter__checkbox__wrapper--mobile', {
                      'search-filter__checkbox__wrapper--mobile--selected': s,
                    })}
                  >
                    <FilterEntry
                      typeName={typeName}
                      selected={s}
                      onClick={() => changeHandler(type, type, !s)}
                      count={count}
                    />
                  </div>
                ))
              )}
            {filters
              .filter(({ name }) => !HOISTED_DIETS.includes(name))
              .map(({ name, filter }) => (
                <MobileSearchFilterButton
                  name={name}
                  count={filter.filter(({ selected: isSelected }) => isSelected).length}
                  onClick={() => setOpenFilter(name)}
                  key={name}
                />
              ))}
          </div>
          <FilterPills
            changeHandler={changeHandler}
            filterOrder={filterOrder}
            selected={selected}
            displayNames={displayNames}
          />
          <ResetFiltersButton
            link={`/food/search?q=${searchTerm}`}
            disabled={
              filters.filter(({ filter: fs }) => fs.filter(f => f.selected).length).length === 0
            }
          />
        </div>
      )}
      {filters.map(
        ({ name, filter }) =>
          (openFilter === name || prevOpenFilter === name) && (
            <MobileSearchFilterMenu
              name={name}
              filter={filter}
              changeHandler={changeHandler}
              clearHandler={clearHandler}
              key={name}
              closing={openFilter !== name}
              back={openMenu}
              close={closeMenu}
            />
          )
      )}
    </div>
  );
};

MobileSearchFiltersMenu.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  clearHandler: PropTypes.func.isRequired,
  displayNames: PropTypes.shape(displayNamesShape).isRequired,
  filters: filtersShape.isRequired,
  filterOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  openFilter: PropTypes.string.isRequired,
  prevOpenFilter: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  selected: PropTypes.shape(selectedShape).isRequired,
  setOpenFilter: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
};

const ConnectedMobileSearchFiltersMenu = connect(
  state => ({
    openFilter: state.searchReducer.openFilter,
    prevOpenFilter: state.searchReducer.prevOpenFilter,
  }),
  { setOpenFilter: setOpenFilterAction }
)(MobileSearchFiltersMenu);

export {
  ConnectedMobileSearchFiltersMenu as default,
  MobileSearchFiltersMenu,
  MobileSearchFilterButton,
};
