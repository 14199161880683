import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FilterList from '../generic/FilterList';
import FilterBar from '../generic/FilterBar';
import { PreviousIcon } from '../../generic/Icons';
import { filterShape } from '../shapes';

if (process.env.CLIENT) {
  require('./MobileSearchFilterMenu.scss'); // eslint-disable-line global-require
}

const MobileSearchFilterMenu = ({
  name,
  filter,
  changeHandler,
  clearHandler,
  closing,
  back,
  close,
}) => {
  const [searchString, setSearchString] = useState('');

  return (
    <div
      className={classNames('mobile-search__filter-menu', 'slide-in-left', {
        'slide-out-right': closing,
      })}
    >
      <div className="mobile-search__filter-menu__header">
        <button
          aria-label="back"
          className="mobile-search__filter-menu__back"
          onClick={back}
          type="button"
        >
          <PreviousIcon />
          <span className="mobile-search__filter-menu__back__text gel-pica-bold">Back</span>
        </button>
        <button
          className="mobile-search__filter-menu__done gel-pica-bold"
          onClick={close}
          type="button"
        >
          Done
        </button>
      </div>
      <FilterBar
        searchString={searchString}
        name={name}
        onChange={value => setSearchString(value)}
      />
      <FilterList
        entries={filter}
        searchString={searchString}
        name={name}
        changeHandler={changeHandler}
        clearHandler={clearHandler}
        isMobile
      />
    </div>
  );
};

MobileSearchFilterMenu.propTypes = {
  name: PropTypes.string.isRequired,
  filter: filterShape.isRequired,
  changeHandler: PropTypes.func.isRequired,
  clearHandler: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  closing: PropTypes.bool,
};

MobileSearchFilterMenu.defaultProps = {
  closing: false,
};

export default MobileSearchFilterMenu;
