import React from 'react';
import PropTypes from 'prop-types';
import { Message, MessageHeading, MessageBody } from './Message';

if (process.env.CLIENT) {
  require('./ErrorMessage.scss'); // eslint-disable-line global-require
}

const ErrorMessage = ({ retry }) => (
  <Message className="error-message">
    <MessageHeading>That didn’t work, there must be a loose connection.</MessageHeading>
    <MessageBody>
      We suggest you{' '}
      <button className="error-message__retry" onClick={retry} type="button">
        try again
      </button>{' '}
      or check your network connection.
    </MessageBody>
  </Message>
);

ErrorMessage.propTypes = {
  retry: PropTypes.func.isRequired,
};

export default ErrorMessage;
