import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

if (process.env.CLIENT) {
  require('./Message.scss'); // eslint-disable-line global-require
}

const Message = ({ children, className, ...props }) => (
  <div className={classNames('message', className)} {...props}>
    {children}
  </div>
);

Message.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Message.defaultProps = {
  children: undefined,
  className: undefined,
};

const MessageHeading = ({ children, className, tag: Tag, ...props }) => (
  <Tag className={classNames('message__heading', className)} {...props}>
    {children}
  </Tag>
);

MessageHeading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  tag: PropTypes.elementType,
};

MessageHeading.defaultProps = {
  children: undefined,
  className: 'gel-double-pica',
  tag: 'h2',
};

const MessageBody = ({ children, className, ...props }) => (
  <div className={classNames('message__body', className)} {...props}>
    {children}
  </div>
);

MessageBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

MessageBody.defaultProps = {
  children: undefined,
  className: 'gel-body-copy',
};

export { Message, MessageHeading, MessageBody };
