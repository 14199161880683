import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FilterList from '../generic/FilterList';
import FilterBar from '../generic/FilterBar';
import { CloseIcon } from '../../generic/Icons';

import { filterShape } from '../shapes';

if (process.env.CLIENT) {
  require('./SearchFilterDropdown.scss'); // eslint-disable-line global-require
}

class SearchFilterDropdown extends Component {
  state = {
    searchString: '',
    screenWidth: 0,
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  changeText = value => this.setState({ searchString: value });

  render() {
    const { name, filter, changeHandler, clearHandler, close, buttonRef } = this.props;
    const { searchString, screenWidth } = this.state;
    const dropdownWidth = 375;

    return (
      <div
        className={classNames('search-filter__dropdown', {
          'right-align': (buttonRef ? buttonRef.offsetLeft : 0) + dropdownWidth + 120 > screenWidth,
        })}
      >
        <div className="search-filter__dropdown__header">
          <button
            aria-label="close"
            className="search-filter__dropdown__close"
            onClick={close}
            type="button"
          >
            <CloseIcon />
          </button>
        </div>
        <FilterBar searchString={searchString} name={name} onChange={this.changeText} />
        <FilterList
          entries={filter}
          searchString={searchString}
          name={name}
          changeHandler={changeHandler}
          clearHandler={clearHandler}
        />
      </div>
    );
  }
}

SearchFilterDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  filter: filterShape.isRequired,
  changeHandler: PropTypes.func.isRequired,
  clearHandler: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  buttonRef: PropTypes.shape({
    offsetLeft: PropTypes.number.isRequired,
  }),
};

SearchFilterDropdown.defaultProps = {
  buttonRef: {
    offsetLeft: 0,
  },
};

export default SearchFilterDropdown;
