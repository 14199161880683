import React from 'react';
import PropTypes from 'prop-types';
import upperFirst from 'lodash/upperFirst';
import { CloseIcon } from '../../generic/Icons';

import { selectedShape, displayNamesShape } from '../shapes';

if (process.env.CLIENT) {
  require('./FilterPills.scss'); // eslint-disable-line global-require
}

const FilterPills = ({ changeHandler, displayNames, filterOrder, selected }) =>
  filterOrder.map(name =>
    selected[name]
      .map(selectedValue => ({
        type: name,
        displayName: displayNames[name][selectedValue],
        id: selectedValue,
      }))
      .map(pill => {
        const { displayName, id, type } = pill;

        return (
          displayName && (
            <button
              key={id}
              aria-label="close"
              className="filter-pill gel-brevier-bold"
              onClick={event => {
                event.preventDefault();
                changeHandler(type, id, false);
              }}
              type="button"
            >
              {upperFirst(displayName)}
              <CloseIcon />
            </button>
          )
        );
      })
  );

FilterPills.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  displayNames: PropTypes.shape(displayNamesShape).isRequired,
  filterOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.shape(selectedShape).isRequired,
};

export default FilterPills;
