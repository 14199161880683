import PropTypes from 'prop-types';

export const countsShape = PropTypes.arrayOf(
  PropTypes.shape({
    type: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
  })
);

export const filterCountsShape = {
  chefs: countsShape.isRequired,
  courses: countsShape.isRequired,
  cuisines: countsShape.isRequired,
  diets: countsShape.isRequired,
  dishes: countsShape.isRequired,
  occasions: countsShape.isRequired,
  programmes: countsShape.isRequired,
};

export const selectedShape = {
  chefs: PropTypes.arrayOf(PropTypes.string).isRequired,
  courses: PropTypes.arrayOf(PropTypes.string).isRequired,
  cuisines: PropTypes.arrayOf(PropTypes.string).isRequired,
  diets: PropTypes.arrayOf(PropTypes.string).isRequired,
  dishes: PropTypes.arrayOf(PropTypes.string).isRequired,
  occasions: PropTypes.arrayOf(PropTypes.string).isRequired,
  programmes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export const displayNamesShape = {
  chefs: PropTypes.objectOf(PropTypes.string).isRequired,
  courses: PropTypes.objectOf(PropTypes.string).isRequired,
  cuisines: PropTypes.objectOf(PropTypes.string).isRequired,
  diets: PropTypes.objectOf(PropTypes.string).isRequired,
  dishes: PropTypes.objectOf(PropTypes.string).isRequired,
  occasions: PropTypes.objectOf(PropTypes.string).isRequired,
  programmes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export const filterEntryShape = PropTypes.shape({
  type: PropTypes.string.isRequired,
  typeName: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  selected: PropTypes.bool,
});

export const filterShape = PropTypes.arrayOf(filterEntryShape);

export const filtersShape = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    filter: filterShape.isRequired,
  })
);
