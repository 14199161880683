import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import SearchFilter from './SearchFilter';
import SearchBar from '../searchBar/SearchBar';
import StandardCollection from '../promo/collections/StandardCollection';
import Pagination from '../generic/pagination/Pagination';
import PaginationSummary from '../generic/pagination/PaginationSummary';
import SearchMessages from './generic/SearchMessages';
import SeasonalPromoBanner from '../generic/seasonalPromo/SeasonalPromoBanner';
import LoadingOverlay from '../generic/LoadingOverlay';
import FocusTarget from '../generic/FocusTarget';
import { scrollIntoView } from '../generic/scrollIntoView';

import { searchAction } from './actions';
import { clearSearchResults } from '../searchBar/actions';
import { promoShape } from '../promo/shapes';
import { selectedShape, displayNamesShape } from './shapes';
import { seasonalPromoShape } from '../generic/seasonalPromo/shapes';
import MetaProperties from '../generic/MetaProperties';

if (process.env.CLIENT) {
  require('./Page.scss'); // eslint-disable-line global-require
}

const validParams = [
  'q',
  'page',
  'chefs',
  'courses',
  'cuisines',
  'diets',
  'dishes',
  'occasions',
  'programmes',
  'quick',
  'inSeason',
];

const getValidSearchParams = location => {
  const params = new URLSearchParams(location.search);
  const sanitizedParams = [...params.entries()].filter(([param]) => validParams.includes(param));
  return new URLSearchParams(sanitizedParams);
};

export class Page extends Component {
  resultsRef = React.createRef();

  componentDidUpdate(prevProps) {
    const { openFilter, isMobile, location } = this.props;

    const body = document.getElementsByTagName('body')[0];
    if (openFilter && isMobile) {
      body.classList.add('no-scroll');
      body.classList.add('slide-out-left');
    } else if (body.classList.contains('no-scroll')) {
      body.classList.add('slide-in-right');
      body.classList.remove('slide-out-left');
      setTimeout(() => {
        body.classList.remove('no-scroll');
        body.classList.remove('slide-in-right');
      }, 500);
    }

    if (prevProps.location !== location) {
      this.search(prevProps);
    }
  }

  onSearchSubmit = searchTerm => {
    const { history, location } = this.props;
    const params = searchTerm ? new URLSearchParams({ q: searchTerm }) : '';
    history.push({ pathName: location.pathName, search: params.toString() });
  };

  onChangeFilter = (filter, value, checked) => {
    this.props.clearSearchResults();
    const { history, location } = this.props;
    const params = getValidSearchParams(location);

    const param = params.get(filter);
    const set = param ? new Set(param.split(',')) : new Set();
    if (checked) {
      set.add(value);
    } else {
      set.delete(value);
    }
    if (set.size > 0) {
      params.set(filter, Array.from(set).join(','));
    } else {
      params.delete(filter);
    }
    params.delete('page');

    history.push({ search: decodeURIComponent(params.toString()) });
  };

  onClearFilter = filter => {
    const { history, location } = this.props;
    const params = getValidSearchParams(location);

    params.delete(filter);
    params.delete('page');

    history.push({ search: decodeURIComponent(params.toString()) });
  };

  search = prevProps => {
    const { location, searchAction: action } = this.props;

    const params = new URLSearchParams(location.search);
    const searchTerm = params.get('q') || '';
    const page = parseInt(params.get('page') || 1, 10);
    const prevPage = parseInt(new URLSearchParams(prevProps.location.search).get('page') || 1, 10);

    if (prevPage !== page) {
      scrollIntoView(this.resultsRef.current);
      this.resultsRef.current.focus();
    }

    const chefs = params.get('chefs');
    const courses = params.get('courses');
    const cuisines = params.get('cuisines');
    const diets = params.get('diets');
    const dishes = params.get('dishes');
    const occasions = params.get('occasions');
    const programmes = params.get('programmes');
    const quick = params.get('quick');
    const inSeason = params.get('inSeason');

    const selected = {
      chefs: chefs ? chefs.split(',') : [],
      courses: courses ? courses.split(',') : [],
      cuisines: cuisines ? cuisines.split(',') : [],
      diets: diets ? diets.split(',') : [],
      dishes: dishes ? dishes.split(',') : [],
      occasions: occasions ? occasions.split(',') : [],
      programmes: programmes ? programmes.split(',') : [],
      quick: quick ? quick.split(',') : [],
      inSeason: inSeason ? inSeason.split(',') : [],
    };

    action({ searchTerm, page, selected });
  };

  render() {
    const {
      page,
      recipes,
      totalCount,
      location,
      searchTerm,
      selected,
      displayNames,
      loading,
      error,
      seasonalPromo,
      foodImagesPath,
    } = this.props;

    const invalidFilters = Object.entries(selected).reduce((map, [name, filters]) => {
      const invalid = filters.filter(
        invalidFilter => !Object.keys(displayNames[name]).includes(invalidFilter)
      );
      if (invalid.length > 0) {
        map[name] = invalid; // eslint-disable-line no-param-reassign
      }
      return map;
    }, {});

    const errorPage =
      error ||
      recipes.length === 0 ||
      Object.values(invalidFilters).filter(f => f.length > 0).length;

    return (
      <>
        <MetaProperties
          title="Search recipes"
          description="Discover more than 10,000 fantastic recipes on BBC Food."
          canonical="/food/search"
        />
        <div
          className={classNames('search-page__searchbar', {
            'search-page__searchbar--with-results': searchTerm.length > 0 || recipes.length > 0,
          })}
        >
          <div className="gel-wrap">
            <SearchBar onSubmit={this.onSearchSubmit} />
          </div>
        </div>
        {(searchTerm.length > 0 || recipes.length > 0) && (
          <SearchFilter changeHandler={this.onChangeFilter} clearHandler={this.onClearFilter} />
        )}
        <LoadingOverlay
          active={loading}
          className={classNames({
            'search-page__loading-overlay--error': errorPage,
          })}
        >
          {!errorPage ? (
            <>
              <FocusTarget aria-label="Results top" ref={this.resultsRef} />
              <PaginationSummary page={page} pageSize={24} totalCount={totalCount}>
                results for{' '}
                <span className="search-page__search-term gel-pica-bold">{searchTerm}</span>
              </PaginationSummary>
              <StandardCollection
                containerClassName="search-page__promo-collection"
                promos={recipes}
                maxCollectionSize={24}
                standard={false}
              />
              <Pagination
                clientSide
                page={page}
                pages={Math.ceil(totalCount / 24)}
                buildUrl={p => {
                  const params = getValidSearchParams(location);
                  params.set('page', p);
                  return `${location.pathname}?${decodeURIComponent(params.toString())}`;
                }}
              />
              {seasonalPromo && (
                <SeasonalPromoBanner season={seasonalPromo} foodImagesPath={foodImagesPath} />
              )}
            </>
          ) : (
            <div className="gel-wrap">
              <SearchMessages
                searchTerm={searchTerm}
                selected={selected}
                invalidFilters={invalidFilters}
                error={error}
                search={() => this.search(this.props)}
              />
            </div>
          )}
        </LoadingOverlay>
      </>
    );
  }
}

Page.defaultProps = {
  isMobile: false,
  loading: false,
  error: false,
  seasonalPromo: undefined,
};

Page.propTypes = {
  page: PropTypes.number.isRequired,
  recipes: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  totalCount: PropTypes.number.isRequired,
  openFilter: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  selected: PropTypes.shape(selectedShape).isRequired,
  displayNames: PropTypes.shape(displayNamesShape).isRequired,
  history: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  location: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  searchAction: PropTypes.func.isRequired,
  clearSearchResults: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  seasonalPromo: PropTypes.shape(seasonalPromoShape),
  foodImagesPath: PropTypes.string.isRequired,
};

export default connect(
  state => ({
    page: state.searchReducer.page,
    recipes: state.searchReducer.recipes,
    totalCount: state.searchReducer.totalCount,
    openFilter: state.searchReducer.openFilter,
    isMobile: state.pageReducer.isMobile,
    searchTerm: state.searchReducer.searchTerm,
    selected: state.searchReducer.selected,
    displayNames: state.searchReducer.displayNames,
    loading: state.searchReducer.loading,
    error: state.searchReducer.error,
    seasonalPromo: state.pageReducer.seasonalPromo,
    foodImagesPath: state.pageReducer.foodImagesPath,
  }),
  { searchAction, clearSearchResults }
)(Page);
