import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import MobileSearchFiltersMenu from './mobile/MobileSearchFiltersMenu';
import SearchFilterBar from './desktop/SearchFilterBar';
import FilterPills from './generic/FilterPills';
import ResetFiltersButton from './generic/ResetFiltersButton';
import ContentSepartor from '../generic/ContentSeparator';

import { filterCountsShape, selectedShape, displayNamesShape } from './shapes';
import { promoShape } from '../promo/shapes';

if (process.env.CLIENT) {
  require('./SearchFilter.scss'); // eslint-disable-line global-require
}

const parseFilterData = (filterOrderArray, displayNames, filterCounts, selected) =>
  filterOrderArray.map(name => ({
    name,
    filter: Object.entries(displayNames[name]) // eslint-disable-line no-param-reassign
      .map(([type, typeName]) => {
        const [entity] =
          (filterCounts[name] &&
            filterCounts[name].filter(filterCount => type === filterCount.type)) ||
          [];

        return {
          type,
          typeName,
          count: entity ? entity.count : 0,
          selected: selected[name] && selected[name].includes(type),
        };
      })
      .sort((a, b) => a.typeName.localeCompare(b.typeName)),
  }));

const filterOrder = [
  'quick',
  'inSeason',
  'courses',
  'cuisines',
  'diets',
  'dishes',
  'occasions',
  'chefs',
  'programmes',
];

const SearchFilter = ({
  filterCounts,
  selected,
  displayNames,
  totalCount,
  searchTerm,
  changeHandler,
  clearHandler,
  isMobile,
}) => {
  const filters = parseFilterData(filterOrder, displayNames, filterCounts, selected);

  const filterProps = {
    filters,
    changeHandler,
    filterOrder,
    clearHandler,
    totalCount,
    searchTerm,
    selected,
    displayNames,
  };

  return (
    <>
      <MobileSearchFiltersMenu {...filterProps} />
      {!isMobile && <SearchFilterBar {...filterProps} />}
      {Object.values(selected).filter(fs => fs.length).length > 0 && (
        <>
          <div className="search__current-filters-container gel-wrap">
            <div className="gel-layout">
              <div className="gel-layout__item">
                <FilterPills {...filterProps} />
                <ResetFiltersButton link={`/food/search?q=${searchTerm}`} />
              </div>
            </div>
          </div>
          <ContentSepartor />
        </>
      )}
    </>
  );
};

SearchFilter.propTypes = {
  recipes: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  totalCount: PropTypes.number.isRequired,
  filterCounts: PropTypes.shape(filterCountsShape).isRequired,
  selected: PropTypes.shape(selectedShape).isRequired,
  displayNames: PropTypes.shape(displayNamesShape).isRequired,
  openFilter: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  clearHandler: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default connect(state => ({
  recipes: state.searchReducer.recipes,
  totalCount: state.searchReducer.totalCount,
  filterCounts: state.searchReducer.filterCounts,
  selected: state.searchReducer.selected,
  displayNames: state.searchReducer.displayNames,
  openFilter: state.searchReducer.openFilter,
  searchTerm: state.searchReducer.searchTerm,
  isMobile: state.pageReducer.isMobile,
}))(SearchFilter);
