import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import pluralize from 'pluralize';
import { connect } from 'react-redux';

import SearchFilterDropdown from './SearchFilterDropdown';
import { DownIcon, UpIcon } from '../../generic/Icons';
import { setOpenFilter } from '../actions';
import { filterShape } from '../shapes';

if (process.env.CLIENT) {
  require('./SearchFilter.scss'); // eslint-disable-line global-require
}

const SearchFilterButton = ({ name, onClick, selected, count }) => (
  <button
    className={classnames('search-filter__button', {
      'search-filter__button--highlighted': selected || count > 0,
    })}
    onClick={onClick}
    type="button"
  >
    {`${count > 0 ? count : 'All'} ${pluralize(name, count)}`}
    {selected ? <UpIcon /> : <DownIcon />}
  </button>
);

SearchFilterButton.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
};

class SearchFilter extends Component {
  ref = React.createRef();

  onClick = () => this.props.setOpenFilter(this.props.openFilter ? '' : this.props.name);

  handleClickOutside = () => {
    if (this.props.openFilter === this.props.name) {
      this.props.setOpenFilter('');
    }
  };

  render() {
    const { name, filter, changeHandler, clearHandler, openFilter } = this.props;

    return (
      <span className="search-filter" ref={this.ref}>
        <SearchFilterButton
          name={name}
          onClick={this.onClick}
          selected={openFilter === name}
          count={filter.filter(({ selected }) => selected).length}
        />
        {openFilter === name && (
          <SearchFilterDropdown
            name={name}
            filter={filter}
            changeHandler={changeHandler}
            clearHandler={clearHandler}
            close={this.handleClickOutside}
            buttonRef={this.ref.current}
          />
        )}
      </span>
    );
  }
}

SearchFilter.propTypes = {
  name: PropTypes.string.isRequired,
  filter: filterShape.isRequired,
  changeHandler: PropTypes.func.isRequired,
  clearHandler: PropTypes.func.isRequired,
  openFilter: PropTypes.string.isRequired,
  setOpenFilter: PropTypes.func.isRequired,
};

const SearchFilterWrapper = connect(state => ({ openFilter: state.searchReducer.openFilter }), {
  setOpenFilter,
})(onClickOutside(SearchFilter));

export { SearchFilterWrapper as default, SearchFilter, SearchFilterButton, SearchFilterDropdown };
