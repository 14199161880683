import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

if (process.env.CLIENT) {
  require('./ResetFiltersButton.scss'); // eslint-disable-line global-require
}

const ResetFiltersButton = ({ link, disabled }) =>
  disabled ? (
    <span className="search-filter__reset-link gel-brevier-bold disabled">Reset all filters</span>
  ) : (
    <Link to={link} className="search-filter__reset-link gel-brevier-bold">
      Reset all filters
    </Link>
  );

ResetFiltersButton.propTypes = {
  link: PropTypes.string,
  disabled: PropTypes.bool,
};

ResetFiltersButton.defaultProps = {
  link: '',
  disabled: false,
};

export default ResetFiltersButton;
