import React from 'react';
import deburr from 'lodash/deburr';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FilterEntry } from './FilterEntry';

import { filterShape } from '../shapes';

if (process.env.CLIENT) {
  require('./FilterList.scss'); // eslint-disable-line global-require
}

const FilterList = ({ entries, name, searchString, isMobile, changeHandler, clearHandler }) => {
  const selectedCount = entries.filter(({ selected }) => selected).length;

  return (
    <ul
      className={classNames('search-filter__filter-list', {
        'search-filter__filter-list--mobile': isMobile,
      })}
    >
      <li
        className={classNames('search-filter__filter-list__row', {
          'search-filter__filter-list__row--selected': selectedCount === 0,
        })}
      >
        <FilterEntry
          typeName={`All ${name}`}
          selected={selectedCount === 0}
          onClick={selectedCount > 0 ? () => clearHandler(name) : undefined}
        />
      </li>
      {entries
        .filter(({ selected, count }) => selected || count > 0)
        .filter(({ typeName }) =>
          deburr(typeName.toLowerCase()).includes(deburr(searchString.toLowerCase()))
        )
        .map(({ type, typeName, count, selected }) => (
          <li
            className={classNames('search-filter__filter-list__row', {
              'search-filter__filter-list__row--selected': selected,
            })}
            key={type}
          >
            <FilterEntry
              typeName={typeName}
              count={count}
              selected={selected}
              onClick={() => changeHandler(name, type, !selected)}
            />
          </li>
        ))}
    </ul>
  );
};

FilterList.propTypes = {
  entries: filterShape.isRequired,
  name: PropTypes.string.isRequired,
  searchString: PropTypes.string,
  isMobile: PropTypes.bool,
  changeHandler: PropTypes.func.isRequired,
  clearHandler: PropTypes.func.isRequired,
};

FilterList.defaultProps = {
  searchString: '',
  isMobile: false,
};

export { FilterList as default, FilterList, FilterEntry };
