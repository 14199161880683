import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { seasonalPromoShape } from './shapes';

if (process.env.CLIENT) {
  require('./SeasonalPromoBanner.scss'); // eslint-disable-line global-require
}

const SeasonalPromoBanner = ({
  season: {
    title,
    subtitle,
    url,
    id,
    styles: { backgroundColorLeft, backgroundColorRight, titleColor, subtitleColor },
  },
  foodImagesPath,
}) => (
  <a className="seasonal-promo-banner" href={`/food${url}`}>
    <div
      className="seasonal-promo-banner__colour"
      style={{ backgroundColor: backgroundColorLeft }}
    />
    <div
      className="seasonal-promo-banner__colour"
      style={{ backgroundColor: backgroundColorRight }}
    />
    <div
      className={classnames('seasonal-promo-banner__wrap')}
      style={{
        backgroundImage: `url(${foodImagesPath}/food/timed_promos/${id}.jpg)`,
      }}
    >
      <div className="gel-wrap">
        <div className="gel-layout">
          <div className="gel-layout__item gel-1/1">
            <div className="seasonal-promo-banner__text">
              <p
                className={classnames(
                  'seasonal-promo-banner__title',
                  'gel-trafalgar-bold',
                  'b-font-family-serif'
                )}
                style={{ color: titleColor }}
              >
                {title}
              </p>
              <p
                className={classnames(
                  'seasonal-promo-banner__recipes',
                  'gel-trafalgar',
                  'b-font-family-serif'
                )}
                style={{ color: subtitleColor }}
              >
                {subtitle || 'Recipes'}
              </p>
              <p
                className="seasonal-promo-banner__subtitle gel-long-primer"
                style={{ color: subtitleColor }}
              >
                Collection
              </p>
              <p className="seasonal-promo-banner__button gel-pica-bold">Get recipes</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a>
);

SeasonalPromoBanner.propTypes = {
  season: PropTypes.shape(seasonalPromoShape).isRequired,
  foodImagesPath: PropTypes.string.isRequired,
};

export default SeasonalPromoBanner;
