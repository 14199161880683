import {
  SET_SEARCH_PAGE_DATA,
  SET_DISPLAY_NAMES_LOOKUP,
  SET_OPEN_FILTER,
  SET_PREV_FILTER,
  UPDATE_REQUEST,
  UPDATE_RESULTS,
  UPDATE_ERROR,
} from './constants';
import { updateDisplaySearchTerm } from '../searchBar/actions';

export const setSearchPageData = ({
  searchTerm,
  page,
  recipes,
  totalCount,
  filterCounts,
  selected,
}) => ({
  type: SET_SEARCH_PAGE_DATA,
  searchTerm,
  page,
  recipes,
  totalCount,
  filterCounts,
  selected,
});

export const updateRequest = ({ searchTerm, page, selected }) => ({
  type: UPDATE_REQUEST,
  searchTerm,
  page,
  selected,
});

export const updateResults = ({
  filterCounts,
  page,
  recipes,
  searchTerm,
  selected,
  totalCount,
}) => ({
  type: UPDATE_RESULTS,
  filterCounts,
  page,
  recipes,
  searchTerm,
  selected,
  totalCount,
});

export const updateError = () => ({
  type: UPDATE_ERROR,
});

export const setDisplayNameLookup = ({ displayNames }) => ({
  type: SET_DISPLAY_NAMES_LOOKUP,
  displayNames,
});

export const searchAction = args => async dispatch => {
  const { searchTerm, selected, page } = args;

  dispatch(updateDisplaySearchTerm(searchTerm));
  dispatch(updateRequest(args));

  await fetch(
    `/food/api/search?${new URLSearchParams({ q: searchTerm, ...selected, page }).toString()}`
  )
    .then(rs => rs.json())
    .then(response => dispatch(updateResults({ ...args, ...response })))
    .catch(() => dispatch(updateError()));
};

export const setOpenFilter = openFilter => async dispatch => {
  dispatch({
    type: SET_OPEN_FILTER,
    openFilter,
  });
  setTimeout(() => {
    dispatch({
      type: SET_PREV_FILTER,
    });
  }, 500);
};
