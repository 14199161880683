import React from 'react';
import PropTypes from 'prop-types';

import { CloseIcon, SearchIcon } from '../../generic/Icons';

if (process.env.CLIENT) {
  require('./FilterBar.scss'); // eslint-disable-line global-require
}

const FilterBar = ({ searchString, name, onChange }) => (
  <div className="filter-bar">
    <input
      type="text"
      value={searchString}
      onChange={e => onChange(e.target.value)}
      placeholder={`Search all ${name}`}
    />
    {searchString && (
      <button onClick={() => onChange('')} type="button">
        <CloseIcon />
      </button>
    )}
    <SearchIcon />
  </div>
);

FilterBar.propTypes = {
  searchString: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default FilterBar;
