import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Message, MessageHeading, MessageBody } from '../../generic/message/Message';
import ErrorMessage from '../../generic/message/ErrorMessage';
import { selectedShape } from '../shapes';

if (process.env.CLIENT) {
  require('./SearchMessages.scss'); // eslint-disable-line global-require
}

const SearchLandingMessage = () => (
  <Message>
    <MessageHeading tag="h1" className="gel-paragon">
      Discover something delicious
    </MessageHeading>
    <MessageBody>
      Search and find inspiration from thousands of tasty and healthy recipes.
    </MessageBody>
  </Message>
);

const InvalidFilterHeading = ({ invalidFilters }) => (
  <MessageHeading>
    Sorry, we can’t find
    {Object.entries(invalidFilters).reduce(
      (prev, [name, filters]) => (
        <>
          {prev} {prev ? ' or' : ''} the {pluralize(name, filters.length)}{' '}
          <span className="search-messages__search-term gel-double-pica-bold" key={name}>
            {filters.join(', ')}
          </span>
        </>
      ),
      null
    )}
  </MessageHeading>
);

InvalidFilterHeading.propTypes = {
  invalidFilters: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const SearchErrorMessage = ({ searchTerm, selected, invalidFilters }) => {
  const filtersSelected = Object.values(selected).filter(f => f.length).length > 0;
  const hasInvalidFilters = Object.values(invalidFilters).filter(f => f.length > 0).length > 0;

  return (
    <Message className="search-messages">
      {hasInvalidFilters ? (
        <InvalidFilterHeading invalidFilters={invalidFilters} />
      ) : (
        <MessageHeading>
          Sorry, there are no results for{' '}
          <span className="search-messages__search-term gel-double-pica-bold">{searchTerm}</span>
          {filtersSelected && ' with the selected filters'}
        </MessageHeading>
      )}
      <MessageBody>
        Suggestions
        <ul className="search-messages__suggestions-list">
          {filtersSelected && <li>Remove the filters by selecting “Reset all filters”.</li>}
          <li>Make sure that all words are spelled correctly.</li>
          <li>Try different keywords.</li>
          <li>Try more general keywords.</li>
        </ul>
      </MessageBody>
    </Message>
  );
};

SearchErrorMessage.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  selected: PropTypes.shape(selectedShape).isRequired,
  invalidFilters: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const SearchMessages = ({ searchTerm, selected, invalidFilters, error, search }) => {
  if (error) {
    return <ErrorMessage retry={search} />;
  }

  if (searchTerm || Object.keys(invalidFilters).length) {
    return (
      <SearchErrorMessage
        searchTerm={searchTerm}
        selected={selected}
        invalidFilters={invalidFilters}
      />
    );
  }

  return <SearchLandingMessage />;
};

SearchMessages.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  selected: PropTypes.shape(selectedShape).isRequired,
  invalidFilters: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  error: PropTypes.bool.isRequired,
  search: PropTypes.func.isRequired,
};

export {
  SearchMessages as default,
  SearchLandingMessage,
  InvalidFilterHeading,
  SearchErrorMessage,
};
