/* eslint-disable react/jsx-curly-brace-presence */

import React from 'react';
import upperFirst from 'lodash/upperFirst';
import PropTypes from 'prop-types';
import { TickIcon } from '../../generic/Icons';

if (process.env.CLIENT) {
  require('./FilterEntry.scss'); // eslint-disable-line global-require
}

export const FilterEntry = ({ typeName, count, selected, onClick }) => (
  <label className="search-filter__checkbox">
    <input
      className="search-filter__checkbox__input"
      type="checkbox"
      selected={selected}
      onChange={onClick}
      disabled={false}
    />
    {<span className="search-filter__checkbox__icon">{selected && <TickIcon />}</span>}
    <span className="search-filter__checkbox__name" title={upperFirst(typeName)}>
      {upperFirst(typeName)}
    </span>
    {count !== undefined && (
      <span className="search-filter__checkbox__count gel-brevier">{count}</span>
    )}
  </label>
);

FilterEntry.propTypes = {
  typeName: PropTypes.string.isRequired,
  count: PropTypes.number,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

FilterEntry.defaultProps = {
  count: undefined,
  selected: false,
  onClick: undefined,
};

export default FilterEntry;
