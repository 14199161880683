import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LoadingIcon } from './Icons';

if (process.env.CLIENT) {
  require('./LoadingOverlay.scss'); // eslint-disable-line global-require
}

const LoadingOverlay = ({ active, children, className, ...props }) => (
  <div
    className={classNames('loading-overlay', { 'loading-overlay--active': active }, className)}
    {...props}
  >
    {active && <LoadingIcon />}
    {children}
  </div>
);

LoadingOverlay.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

LoadingOverlay.defaultProps = {
  active: false,
  children: undefined,
  className: undefined,
};

export default LoadingOverlay;
