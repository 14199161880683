import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SearchFilter from './SearchFilter';
import { filtersShape } from '../shapes';
import { FilterEntry } from '../generic/FilterEntry';
import HOISTED_DIETS from '../generic/hoistedDiets.json';

if (process.env.CLIENT) {
  require('./SearchFilterBar.scss'); // eslint-disable-line global-require
}

const SearchFilterBar = ({ filters, changeHandler, clearHandler }) => (
  <div className="search-filterbar__container">
    <div className="search-filterbar gel-wrap">
      <div className="gel-layout">
        <div className="gel-layout__item">
          <span className="search-filterbar__label">Filter by:</span>
          <div className="search-filterbar__wrapper">
            {filters
              .filter(({ name }) => HOISTED_DIETS.includes(name))
              .map(({ filter }) =>
                filter.map(({ type, typeName, selected }) => (
                  <div
                    key={type}
                    className={classNames('search-filter__checkbox__wrapper--desktop', {
                      'search-filter__checkbox__wrapper--desktop--selected': selected,
                    })}
                  >
                    <FilterEntry
                      typeName={typeName}
                      selected={selected}
                      onClick={() => changeHandler(type, type, !selected)}
                    />
                  </div>
                ))
              )}

            {filters
              .filter(({ name }) => !HOISTED_DIETS.includes(name))
              .map(({ name, filter }) => (
                <SearchFilter
                  name={name}
                  filter={filter}
                  changeHandler={changeHandler}
                  clearHandler={clearHandler}
                  key={name}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

SearchFilterBar.propTypes = {
  filters: filtersShape.isRequired,
  changeHandler: PropTypes.func.isRequired,
  clearHandler: PropTypes.func.isRequired,
};

export default SearchFilterBar;
