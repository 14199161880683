import PropTypes from 'prop-types';

export const seasonalPromoShape = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  styles: PropTypes.shape({
    backgroundColorLeft: PropTypes.string,
    backgroundColorRight: PropTypes.string,
    titleColor: PropTypes.string.isRequired,
    subtitleColor: PropTypes.string.isRequired,
  }),
};
